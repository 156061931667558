<template>
  <div class="container commonDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Mine/MyRegister' }"
        >登记列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>登记详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="commonWhiteBg" v-if="orderInfo">
      <div class="topSteps">
        <el-steps :active="orderInfo.step">
          <el-step title="支付">
            <div slot="description">
              {{orderInfo.status == "10" ? "等待支付" : paymentInfo.payTimeText}}
            </div>
            <a-icon v-if="orderInfo.status != '10'" slot="icon" type="check" />
            <a-icon v-else slot="icon" type="redo" />
          </el-step>
          <el-step title="登记审核">
            <template v-if="orderInfo.status == '11' || orderInfo.status == '20'">
              <div slot="description">审核时间根据加急情况而定</div>
              <a-icon slot="icon" type="exclamation" />
            </template>
            <template v-else-if="orderInfo.status == '12'">
              <div slot="description">审核中，请稍后</div>
              <a-icon slot="icon" type="redo" />
            </template>
            <template v-else>
              <div slot="description">2020.12.29 12:10:54</div>
              <a-icon slot="icon" type="check" />
            </template>
          </el-step>
          <el-step title="登记证书">
            <template v-if="orderInfo.status == '14'">
              <div slot="description">获得登记证书</div>
              <a-icon slot="icon" type="redo" />
            </template>
            <template v-else-if="orderInfo.status == '21'">
              <div slot="description">{{orderInfo.updateTimeText}}</div>
              <a-icon slot="icon" type="check" />
            </template>
              <template v-else>
              <a-icon slot="icon" type="exclamation" />
            </template>
          </el-step>
        </el-steps>
        <div class="rightMain">
          <div>
            <span class="label"> 订单编号：</span>
            <span class="value"> {{ orderInfo.orderNo }} </span>
          </div>
          <div>
            <div> <span class="label"> 支付方式：</span> <span class="value"> {{orderInfo.paymodeText}}（{{orderInfo.orderAmount}}元）</span> </div>
          </div>
          <div>
            <div><span class="label"> 订单状态：</span> <span class="value" :class="{'stausCell1':orderInfo.status=='31',
            'stausCell2':orderInfo.status=='40'|| orderInfo.status=='31'|| orderInfo.status=='32',
            'stausCell3':orderInfo.status=='11'|| orderInfo.status=='12' || orderInfo.status=='13' || orderInfo.status=='20',
            'stausCell4':orderInfo.status=='10',
            'stausCell5':orderInfo.status=='21',}">{{orderInfo.statusText}}</span></div>
          </div>
          <div class="btnWrap" v-if="orderInfo.status == '10'">
            <!-- 登记支付 -->
            <el-button class="onGoPay" type="primary" size="mini" v-if="orderInfo.issuingAuthority == 'HUBEI'" @click="onGoPay">立即支付</el-button>
            <!-- DCI数字登记支付 -->
            <el-button class="onGoPay2" type="primary" size="mini" v-if="orderInfo.issuingAuthority == 'NATIONAL' && orderInfo.status == '10' && orderInfo.applyStatus == 'PAY'" @click="onGoPay2">立即支付</el-button>
          </div>
        </div>
      </div>

      <div class="pdfShow" v-if="orderInfo.status == '21'">
        <div>
          <div class="commonTitle">登记证书</div>
          <p class="tittle">温馨提示：</p>
          <p class="desc">
            1、使用中国知链微信小程序，扫描二维码可查验证书真伪；<br />
            2、通过中国知链防伪查询网址可查验真伪。<br />
          </p>
          <div class="btnWrap">
            <el-button type="primary" size="mini" @click="downloadCertificate">下载存证证书</el-button>
          </div>
        </div>
        <div>
          <el-image
            class="img"
            v-if="orderInfo.certificateInfo"
            :src="orderInfo.certificateInfo.jpgUrl"
            :preview-src-list="[orderInfo.certificateInfo.jpgUrl]"
          >
          </el-image>
        </div>
      </div>

      <div class="commonTitle mt32">登记信息</div>
      <div class="MainStepsForm">
        <div class="formWrap">
          <el-form ref="form" label-width="250px">
            <el-form-item v-if="orderInfo.remark" label="驳回原因" prop="remark" style="color: red;">
              {{orderInfo.remark}}
            </el-form-item>
            <el-form-item label="订单编号" prop="orderNo">
              {{ orderInfo.orderNo }}
            </el-form-item>
            <el-form-item label="支付方式" prop="paymodeText">
              {{ orderInfo.paymodeText }}（{{orderInfo.orderAmount}}元）
            </el-form-item>
            <el-form-item label="登记文件" prop="evidenceFile">
              <div class="nameRow">
                <span v-for="(file, index) in orderInfo.items" :key="index"
                  >{{ file.sourceName }} &nbsp;</span
                >
                <!-- <router-link
                  :to="{ path: '/ExistingEvidence/FileList', query: { orderNo: orderNo,bizType:'register' } }"
                >
                  查看文件
                </router-link> -->
              </div>
            </el-form-item>
            <el-form-item label="登记名称">
              {{ orderInfo.subject }}
            </el-form-item>
            <el-form-item label="登记机构">
              <span v-if="orderInfo.issuingAuthority == 'HUBEI'">湖北省版权局</span>
              <span v-if="orderInfo.issuingAuthority == 'NATIONAL'">国家版权局</span>
            </el-form-item>
            <!-- 2024.1.2修改省局不显示下面状态 -->
            <el-form-item label="登记状态" v-if="orderInfo.applyStatus && orderInfo.issuingAuthority == 'NATIONAL'">
              <!-- CHECK("CHECK", "初审中"),
              CHECK_FAIL("CHECK_FAIL", "初审不通过"),
              PAY("PAY", "待支付"),
              REVIEW("REVIEW", "复审中"),
              TOBE_AMEND("TOBE_AMEND", "待补正"),
              AMEND_CHECK_FAIL("AMEND_CHECK_FAIL", "待补正审核失败"),
              FINISH("FINISH", "数登完成"),
              DISREGARD("DISREGARD", "不予处理"),
              REGISTRATION_STOP("REGISTRATION_STOP", "申请停止"), -->
              <span v-if="orderInfo.applyStatus == 'CHECK'">初审中</span>
              <span v-if="orderInfo.applyStatus == 'CHECK_FAIL'">初审不通过</span>
              <span v-if="orderInfo.applyStatus == 'PAY'">待支付</span>
              <span v-if="orderInfo.applyStatus == 'REVIEW'">复审中</span>
              <span v-if="orderInfo.applyStatus == 'TOBE_AMEND'">待补正</span>
              <span v-if="orderInfo.applyStatus == 'AMEND_CHECK_FAIL'">待补正审核失败</span>
              <span v-if="orderInfo.applyStatus == 'FINISH'">数登完成</span>
              <span v-if="orderInfo.applyStatus == 'DISREGARD'">不予处理</span>
              <span v-if="orderInfo.applyStatus == 'REGISTRATION_STOP'">申请停止</span>
            </el-form-item>
            <el-form-item label="作品类型" prop="worksType" v-if="orderInfo.worksType">
              {{ constants.worksType[orderInfo.worksType * 1-1].label }}
            </el-form-item>
            <el-form-item label="著作权人" prop="right">
              <!-- <span v-for="(author,index) in orderInfo.obligee" :key="index">{{obligees[author]}} &nbsp; </span> -->
              <!-- <span v-for="(author, index) in orderInfo.right" :key="index">{{
                author.obligeeType == "1" ? author.userName : author.companyName
              }}</span> -->
              <span v-if="orderInfo.certificateInfo && JSON.stringify(orderInfo.certificateInfo) != '{}'">{{orderInfo.certificateInfo.body.data.obligee}}</span>
              <span v-else-if="JSON.stringify(orderInfo.certificateInfo) == '{}'"><span v-if="obligeeDetail.obligeeType=='1'">{{obligeeDetail.userName}}</span><span v-if="obligeeDetail.obligeeType=='2'">{{obligeeDetail.companyName}}</span></span>
              <span v-else><span v-if="obligeeDetail.obligeeType=='1'">{{obligeeDetail.userName}}</span><span v-if="obligeeDetail.obligeeType=='2'">{{obligeeDetail.companyName}}</span></span>
            </el-form-item>
            <div v-for="(item,index) in orderInfo.authors" :key="index">
              <el-form-item :label="orderInfo.authors.length>1?'作者'+(index+1):'作者'" prop="name">
                <span style="margin-right: 20px;">{{item.name}}</span>
                <span v-if="item.signatureType == '1'">(本名: {{item.signatureName}})</span>
                <span v-if="item.signatureType == '2'">(别名: {{item.signatureName}})</span>
              </el-form-item>
            </div>
            <el-form-item
              label="权利权属"
              prop="ownership"
              class="ownershipRow"
              v-if="orderInfo.ownership"
            >
              {{ constants.ownership[orderInfo.ownership * 1-1].label }}
            </el-form-item>
            <el-form-item label="权利拥有情况" prop="rightHave">
              <multiple-select
                v-model="orderInfo.copyrights"
                :isResultView="true"
                :listOpt="constants.right"
              ></multiple-select>
            </el-form-item>
            <el-form-item label="权利取得方式" prop="obtainType" v-if="orderInfo.obtainType">
              {{ constants.obtainType[orderInfo.obtainType * 1-1].label }}
            </el-form-item>
            <el-form-item v-if="orderInfo.obtainType == '4'" label="其他权利获得方式说明" prop="obtainTypeDesc.text">
              <el-input
                  v-model="orderInfo.obtainTypeDesc.text"
                  :rows="5"
                  disabled
                  type="textarea"
                ></el-input>
            </el-form-item>
            <!-- <el-form-item label="权利获得方式证明材料" prop="ownershipPicture.additionalProp1" v-if="orderInfo.ownershipPicture && orderInfo.ownershipPicture.additionalProp1">
              <el-upload class="Uploader2 fl" action="" :disabled="true">
                <img v-if="orderInfo.ownershipPicture.additionalProp1" :src="orderInfo.ownershipPicture.additionalProp1" class="avatar" @click="previewImg(orderInfo.ownershipPicture.additionalProp1)">
              </el-upload>
              <el-upload v-if="orderInfo.ownershipPicture.additionalProp2" class="Uploader2 fl" action="" :disabled="true">
                <img v-if="orderInfo.ownershipPicture.additionalProp2" :src="orderInfo.ownershipPicture.additionalProp2" class="avatar" @click="previewImg(orderInfo.ownershipPicture.additionalProp2)">
              </el-upload>
            </el-form-item>
            <el-form-item label="权利归属证明材料" prop="ownershipPicture.additionalProp3" v-if="orderInfo.ownershipPicture && orderInfo.ownershipPicture.additionalProp3">
              <el-upload class="Uploader2 fl" action="" :disabled="true">
                <img v-if="orderInfo.ownershipPicture.additionalProp3" :src="orderInfo.ownershipPicture.additionalProp3" class="avatar" @click="previewImg(orderInfo.ownershipPicture.additionalProp3)">
              </el-upload>
            </el-form-item> -->
            <!-- <el-form-item label="权利保证书" prop="ownershipPicture.additionalProp4" v-if="orderInfo.ownershipPicture && orderInfo.ownershipPicture.additionalProp4">
              <el-upload class="Uploader2 fl" action="" :disabled="true">
                <img v-if="orderInfo.ownershipPicture.additionalProp4" :src="orderInfo.ownershipPicture.additionalProp4" class="avatar" @click="previewImg(orderInfo.ownershipPicture.additionalProp4)">
              </el-upload>
            </el-form-item> -->
            <!-- <el-form-item label="代理委托书" prop="ownershipPicture.additionalProp5" v-if="orderInfo.ownershipPicture && orderInfo.ownershipPicture.additionalProp5">
              <el-upload class="Uploader2 fl" action="" :disabled="true">
                <img v-if="orderInfo.ownershipPicture.additionalProp5" :src="orderInfo.ownershipPicture.additionalProp5" class="avatar" @click="previewImg(orderInfo.ownershipPicture.additionalProp5)">
              </el-upload>
            </el-form-item> -->
            <el-form-item label="作品创作性质" prop="creationNature" v-if="orderInfo.creationProperty">
              {{ constants.creationNature[orderInfo.creationProperty * 1-1].label }}
            </el-form-item>
            <el-form-item v-if="orderInfo.creationNature == '7'" label="作品性质说明" prop="creationPropertyDesc.text">
              <el-input
                v-model="orderInfo.creationPropertyDesc.text"
                :rows="5"
                disabled
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="内容简介" prop="creationIntention.text">
              <el-input
                v-model="orderInfo.creationIntention.text"
                :rows="5"
                disabled
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="创作过程" prop="creationOriginality.text" v-if="orderInfo.creationOriginality">
              <el-input
                v-model="orderInfo.creationOriginality.text"
                :rows="5"
                disabled
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="创作完成时间" prop="finishTime">
              {{ orderInfo.finishTimeText }}
            </el-form-item>
            <el-form-item label="创作完成地点" prop="adress">
              {{ orderInfo.completeLocation.province}}-{{ orderInfo.completeLocation.city}}
            </el-form-item>
            <el-form-item label="作品发表状态" prop="publishStatus">
              {{ constants.publishStatus[orderInfo.publishStatus * 1-1].label }}
            </el-form-item>
            <el-form-item v-if="orderInfo.publishStatus == 2" label="首次发表时间" prop="publishTime">
              {{ orderInfo.publishTimeText }}
            </el-form-item>
            <el-form-item v-if="orderInfo.publishStatus == 2" label="发表地点" prop="adress">
              {{ orderInfo.publishLocation.province}}-{{ orderInfo.publishLocation.city}}
            </el-form-item>

            <!-- <el-form-item label="权利权属" prop="ownership" class="ownershipRow">
              {{ constants.ownership[orderInfo.belong].label }}
            </el-form-item>
            <el-form-item label="作者" prop="author">
              <span v-for="(author,index) in orderInfo.authors" :key="index">{{obligees[author]}} &nbsp; </span>
            </el-form-item>
            <el-form-item label="著作权人" prop="right">
              <span v-for="(author,index) in orderInfo.obligee" :key="index">{{obligees[author]}} &nbsp; </span>
            </el-form-item>
            <el-form-item label="作品创作类型" prop="creationType">
              {{ constants.creationType[orderInfo.creationType].label }}
            </el-form-item>
            <el-form-item label="作品创作性质" prop="creationNature">
              {{ constants.creationNature[orderInfo.creationProperty].label }}
            </el-form-item>
            <el-form-item label="创作完成时间" prop="finishTime">
              {{ orderInfo.finishTimeText }}
            </el-form-item>
            <el-form-item label="创作完成地点" prop="adress">
              {{ orderInfo.completeLocation.province }} {{ orderInfo.completeLocation.city }}{{ orderInfo.completeLocation.area }}
            </el-form-item>
            <el-form-item label="作品发表状态" prop="publishStatus">
              {{ constants.publishStatus[orderInfo.publishStatus].label }}
            </el-form-item>
            <el-form-item label="权利拥有情况" prop="rightHave">
              <multiple-select
                v-model="orderInfo.copyrights"
                :isResultView="true"
                :listOpt="constants.right"
              ></multiple-select>
            </el-form-item>
            <el-form-item label="创作意图" prop="yitu">
              <el-input
                v-model="orderInfo.creationIntention.text"
                :rows="4"
                disabled
                placeholder="请输入创作意图"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="作品独创性" prop="name" class="mt8">
              <el-input
                v-model="orderInfo.creationOriginality.text"
                :rows="4"
                disabled
                placeholder="请输入作品存证名称"
                type="textarea"
              ></el-input>
            </el-form-item> -->
          </el-form>
        </div>
      </div>
      
    </div>
        <pay-dialog ref="payDialog" payForm="register" @close="onSetPayDialogClose"></pay-dialog>
        <pay-dialog ref="payDialog2" payForm="DCIRegister" @close="onSetPayDialogClose"></pay-dialog>

      <!-- 图片预览 -->
      <el-image-viewer
            v-if="showImageViewer"
            :url-list="[showImageUrl]"
            hide-on-click-modal
            teleported
            :on-close="closePre"
        />
  </div>
</template>

<script>
import {
  queryRegisterOrder,
  queryPaymentOrderByOutBizNp,
  paymentRegisterOrder
} from "@/api/EvidenceService";
import { queryObligeeList,queryObligeeDetail } from "@/api/ObligeeService";
import constants from "@/utils/constants";
import PayDialog from "../../components/PayDialog";
import multipleSelect from "../../components/form/multipleSelect";
export default {
  name: "RegisterDetail",
  components: { multipleSelect,PayDialog,
      'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer') 
  },
  data() {
    return {
      constants,
      orderNo: this.$route.query.orderNo,
      orderInfo: null,
      obligeeDetail: {},
      paymentInfo: {},
      stepIdx: 0, // 0 带支付, 1处理中,3 完成
      obligees: {},

      showImageViewer: false,
      showImageUrl: '',
    };
  },
  created() {
    this.loadData();
  },
  methods: {
      getStep(status){
      let step=0;
      if(status=='10'){
        step=0;
      }else if(status*1<21){
        step=1;
      }else {
        step=2;
      }
      return step;
    },
    downloadCertificate() {
      window.open(this.orderInfo.certificateInfo.pdfUrl);
    },
    loadData() {
      queryRegisterOrder(this.orderNo).then(({ data }) => {
        data.statusText=this.getStatus(data.status);
        this.orderInfo = data;
        console.log("orderInfo::::::::",data);
        this.orderInfo.paymodeText = constants.paymode[this.orderInfo.paymode];
        this.orderInfo.finishTimeText = this.dayjs(
          this.orderInfo.completeTime * 1000
        ).format("YYYY-MM-DD");
          this.orderInfo.step=this.getStep(this.orderInfo.status);

        this.orderInfo.createTimeText = this.dayjs(
          this.orderInfo.createTime * 1000
        ).format("YYYY-MM-DD HH:mm:ss ");
        this.orderInfo.updateTimeText = this.dayjs(
          this.orderInfo.updateTime * 1000
        ).format("YYYY-MM-DD HH:mm:ss ");
        if(this.orderInfo.publishTime){
          this.orderInfo.publishTimeText = this.dayjs(
            this.orderInfo.publishTime * 1000
          ).format("YYYY-MM-DD");
        }
        if (this.orderInfo.status != "10") {
          queryPaymentOrderByOutBizNp(this.orderNo, "2").then(({ data }) => {
            this.paymentInfo = data;
            this.paymentInfo.payTimeText = this.dayjs(
              this.paymentInfo.updateTime * 1000
            ).format("YYYY-MM-DD HH:mm:ss ");
          });
        }
        if(this.orderInfo.status!='21'){
            this.timer = setTimeout(this.loadData,30*1000);
        }

        // 获取相关 权利人/作者，以及权利人编码
        queryObligeeDetail(data.obligee[0]).then(({ data }) => {
          console.log('获取权利人相关信息',data);
          this.obligeeDetail = data;
        });
      });

      queryObligeeList(1, 100).then(({ data }) => {
        let obligees={};
        for (let i = 0; i < data.list.length; i++) {
          const element = data.list[i];
            obligees[element.obligeeCode]=element.obligeeType=='1'?element.userName:element.companyName;
        }
        this.obligees = obligees;
      });
    },
     onGoPay() {
      let that = this;
      paymentRegisterOrder(that.orderNo).then(({data})=>{
            that.$refs.payDialog.showDialog(data);
            that.isPayDialogVisible = true;
      })
    
    },
    onGoPay2(){
      let that = this;
      paymentRegisterOrder(that.orderNo).then(({ data }) => {
        this.order = data;
        console.log("order::::::::::::",this.order);
        if (data.paymentStatus == "21") {
          this.bus.$emit("refreshVip");
          this.onSetPayDialogClose(true);
        } else {
          // this.$refs.payDialog.showDialog(data);
          this.isPayDialogVisible = true;
          // console.log(data.paymentRequest.payUrl);
          // 打开第三方支付宝支付接口页面
          window.open(data.paymentRequest.payUrl);
        }
      });
    },
    onSetPayDialogClose(result) {
      console.log(result);
      if (result) {
        this.loadData();
      }
    },

    // 查看证书放大图片
    previewImg(jpgUrl){
        document.body.style.overflow = 'hidden';
        this.showImageUrl = jpgUrl;
        this.showImageViewer = true;
    },
    closePre(){
        document.body.style.overflow = 'auto';
        this.showImageUrl = '';
        this.showImageViewer = false;
    },

    getStatus(code){
      switch(code){
        case '10':return '待付款';
        case '11':return '待处理';
        case '20':return '待处理';
        case '21':return '已完成';
        case '31':return '申领失败';
        case '32':return '初审驳回';
        case '33':return '终审驳回';
        case '40':return '已取消';
      }
    },

  },
  beforeDestroy() { // Vue 2.x
    clearTimeout(this.timer);
  },
};
</script>

<style lang='scss' scoped>
@import "../../assets/css/inputForm.scss";
.formWrap {
  width: 80%!important;
}
.Uploader2, .Uploader3{
    font-size: 14px;
    width: 132px;
    height: 180px;
    text-align: center;
    background: #f9fafc;
    border-radius: 4px;
    border: 1px solid #c0ccda;
    position: relative;
    display: inline-block;
    margin-right: 20px;
}
.Uploader2 img, .Uploader3 img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.commonDetail .pdfShow .img{
  width: 397px;
}
.stausCell1{    // 未通过/失败
    color: #E93030;
}
.stausCell2{    // 已取消
    color: #A6A6A6;

}
.stausCell3{    //审核中
    color: #0063DA;
}
.stausCell4{    //待付款
    color: #CF6E00;
}
.stausCell5{    // 成功
    color: #007F56;
}


@media screen and (max-width: 991px){
  .commonDetail .commonWhiteBg{padding: 20px;}
  .commonDetail .topSteps{display: block;}
  .commonDetail .topSteps .el-steps{width: 100%;padding: 20px;}
  .commonDetail .topSteps .rightMain{margin: 0;padding: 20px;}
  .formWrap{width: 100%!important;}
  .MainStepsForm /deep/.el-form-item__label{width: 120px!important;padding-right: 12px;}
  .MainStepsForm /deep/.el-form-item__content{margin-left: 120px!important;}
}
</style>
